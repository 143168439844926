import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { MainLayout, withLanguageProvider } from '@tmp'
import { GetNewsArticleQuery } from '@gql-types'
import { newsArticleMapping } from '@data'
import Locale from '@src/utils/locale'
import { NewsArticleTable } from '@src/components'
import { useLanguage } from '../context'
import { useNewsData } from '@src/hooks/useNewsData'

const NewsArticleTemplate = ({ data: { article, localizedPages } }: PageProps<GetNewsArticleQuery>) => {
  const { t, locale, dateFormat } = useLanguage()
  const imagePath = article?.photo?.gatsbyImageData?.images?.fallback?.src ?? ''
  const articles = useNewsData(locale, dateFormat)

  const articleContent = () => {
    if (article) {
      const mapType = newsArticleMapping.ContentfulNewsArticle
      const ArticleContent = mapType.component()
      const articleContentProps = mapType.dataToProps(article)
      return <ArticleContent {...articleContentProps} />
    }
    return null
  }

  return (
    <MainLayout
      localizedUrls={localizedPages.nodes.map(page => ({
        locale: page.node_locale as Locale,
        route: { type: 'newsItem', slug: page.slug ?? '' },
      }))}
      seo={{
        ogTitle: article?.title,
        ogImage: { file: { url: imagePath } },
        description: article?.introText?.introText ?? '',
      }}
    >
      {articleContent()}
      <NewsArticleTable numArticles={3} translate={t} articles={articles} seeAllButton standalone={false} />
    </MainLayout>
  )
}

export default withLanguageProvider(NewsArticleTemplate)

export const newsArticleQuery = graphql`
  query GetNewsArticle($contentfulId: String, $locale: String) {
    article: contentfulNewsArticle(contentful_id: { eq: $contentfulId }, node_locale: { eq: $locale }) {
      ...newsArticleFragment
    }

    localizedPages: allContentfulNewsArticle(filter: { contentful_id: { eq: $contentfulId } }) {
      nodes {
        node_locale
        slug
      }
    }
  }
`
