import React, { useState } from 'react'
import { OverviewBar, Text, Link, Icon, InfoRow } from '@cmp'
import { NewsArticleFragmentFragment } from '@gql-types'

import { makePath } from '@src/utils/routes'
import Locale from '@src/utils/locale'

export interface NewsArticleTableProps {
  title?: string
  numArticles?: number
  seeAllButton?: boolean
  articles: NewsArticleFragmentFragment[]
  translate: (word: string) => string
  hideHeaders?: boolean
  standalone?: boolean
}

const VISIBLE_ARTICLES = 8

export const NewsArticleTable = ({
  title = 'Fréttir',
  numArticles = -1,
  seeAllButton,
  articles = [],
  translate = (word: string) => word,
  hideHeaders,
  standalone = true
}: NewsArticleTableProps) => {
  const [visibleArticles, setVisibleArticles] = useState(numArticles)
  return (
    <div>
      {!hideHeaders && (
        <OverviewBar
          colLayout='grid-cols-news'
          labels={[translate('date'), translate('title'), translate('description')]}
          className='hidden text-blue-400 md:grid'
          title={title}
          hugeHeading={standalone}
        />
      )}
      <div className='w-full'>
        {articles.slice(0, visibleArticles).map((article, idx: number) => (
          <Link
            key={idx}
            noStyle
            to={makePath(article.node_locale as Locale, { type: 'newsItem', slug: article.slug ?? '' })}
          >
            <InfoRow
              date={article.createdAt}
              title={article.title ?? ''}
              description={article?.introText?.introText ?? ''}
              columnLayout='grid-cols-news'
              image={article.photo?.gatsbyImageData}
            />
          </Link>
        ))}
      </div>
      {(articles.length > visibleArticles || seeAllButton) && (
        <div className='flex items-center justify-center pb-6 mt-6 text-blue-400 border-b-2 border-black'>
          {seeAllButton ? (
            <Text weight='bold' variant='h3'>
              <Link to='/frettir' noStyle>
                {translate('view-all-news')}
              </Link>
            </Text>
          ) : (
            <button onClick={() => setVisibleArticles(visibleArticles + VISIBLE_ARTICLES)}>
              <Text weight='bold' variant='h3'>
                {translate('load-more-news')}
              </Text>
            </button>
          )}
          <Icon name='Arrow' className='w-4 h-auto ml-4 transform rotate-45 ' />
        </div>
      )}
    </div>
  )
}

export default NewsArticleTable
