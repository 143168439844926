import { useStaticQuery, graphql } from 'gatsby'
import { ContentfulNewsArticle } from '@gql-types'

const useNewsData = (lang: string, dateFormat: (date: number | Date, formatStr: string) => string) => {
  const newsData = useStaticQuery(graphql`
    query newsArticlesQuery {
      is: allContentfulNewsArticle(filter: { node_locale: { eq: "is" },  hide_is: {ne: true} }) {
        nodes {
          ...allNewsFragment
        }
      }
      en: allContentfulNewsArticle(filter: { node_locale: { eq: "en" },  hide_en: {ne: true} }) {
        nodes {
          ...allNewsFragment
        }
      }
    }
  `)
  return (
    newsData[lang]?.nodes
      .sort(
        (a: ContentfulNewsArticle, b: ContentfulNewsArticle) =>
          new Date(b.overwriteDate || b.createdAt).getTime() - new Date(a.overwriteDate || a.createdAt).getTime(),
      )
      .map((article: ContentfulNewsArticle) => {
        return {
          ...article,
          createdAt: dateFormat(new Date(article.overwriteDate || article.createdAt), `do MMMM y`),
        }
      }) || []
  )
}

export { useNewsData }
