import React, { useRef } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import cn from 'classnames'
import { useMouseHovered, useMedia, useMeasure, usePrevious } from 'react-use'
import { motion } from 'framer-motion'
import { clamp } from 'lodash'
import { Text } from '@cmp'
import { ColumnLayout } from '@src/utils'

export interface InfoRowProps {
  description: string
  className?: string
  columnLayout?: ColumnLayout
  date?: string
  detail?: string
  email?: string
  image?: IGatsbyImageData
  title?: string | null
}

const ROTATIONDAMPING = 0.6
const ROTATIONMAX = 40
const ROTATIONMIN = -40
const IMAGE_Y_OFFSET = 30

export const InfoRow = ({
  className = '',
  columnLayout,
  date,
  detail,
  email,
  description,
  image,
  title,
}: InfoRowProps) => {
  const rowRef = useRef(null)

  // track mouse position while hovering over element
  const { docX, elY } = useMouseHovered(rowRef, {
    bound: false,
    whenHovered: true,
  })
  const largeScreen = useMedia('(min-width: 767px)');
  const [imageRef, { width: imageWidth, height: imageHeight }] = useMeasure<HTMLDivElement>()
  const [containerRef, { x: containerX, width: containerWidth }] = useMeasure<HTMLDivElement>()

  const prevMousePosX = usePrevious(docX) || 0
  const movementX = docX - prevMousePosX
  const rotation = clamp(movementX * ROTATIONDAMPING, ROTATIONMIN, ROTATIONMAX)

  return (
    <div ref={containerRef}>
      <div
        ref={rowRef}
        className={cn('md:grid group py-6 items-center border-b border-black', className, columnLayout)}
      >
        <div className='absolute z-20 pointer-events-none'>
          <div ref={imageRef}>
            {image && largeScreen && (
              <motion.div
                className='z-50 w-0 transition-opacity duration-500 ease-out delay-500 opacity-0 md:block group-hover:visible group-hover:opacity-100 group-hover:w-full'
                animate={{
                  x: clamp(docX - imageWidth / 2, containerX, containerWidth - imageWidth),
                  y: elY + imageHeight / 2 - IMAGE_Y_OFFSET,
                  rotate: rotation,
                }}
                transition={{ type: 'spring', stiffness: 60 }}
              >
                <GatsbyImage loading='eager' alt={title ?? ''} image={image} />
              </motion.div>
            )}
          </div>
        </div>
        {date && (
          <Text variant='pAlt' className='pb-6 gutter-l md:pb-0'>
            {date}
          </Text>
        )}
        <Text variant='h3' className={cn('pb-2 md:pb-0 gutter-l md:px-4 first:md:gutter-l')} weight='bold'>
          {title}
        </Text>
        <div className='relative'>
          <Text
            variant={columnLayout === 'grid-cols-searchResults' ? 'intro' : 'pAlt'}
            className={cn('px-4', { 'md:mr-8 md:pr0': !detail })}
          >
            {description}
          </Text>
        </div>
        {detail && (
          <Text
            variant={'pAlt'}
            className={cn('px-4 md:block', { hidden: columnLayout !== 'grid-cols-searchStaffResults' })}
          >
            {detail}
          </Text>
        )}
        {email && (
          <Text variant={'pAlt'} className='px-4 md:block'>
            {email}
          </Text>
        )}
      </div>
    </div>
  )
}

export default InfoRow
